import React from "react";
import "./controls.scss";

export default function Controls({
  setGameLevel,
  setBoardSize,
  gameLevel,
  boardSize,
}) {
  return (
    <div className="controlsContainer">
      <div className="controlInnerContainer">
        <span>Lauko dydis:</span>
        <button
          className={boardSize === 5 ? "active" : "inactive"}
          onClick={() => setBoardSize(5)}
        >
          5
        </button>
        <button
          className={boardSize === 10 ? "active" : "inactive"}
          onClick={() => setBoardSize(10)}
        >
          10
        </button>
        <button
          className={boardSize === 15 ? "active" : "inactive"}
          onClick={() => setBoardSize(15)}
        >
          15
        </button>
        <button
          className={boardSize === 20 ? "active" : "inactive"}
          onClick={() => setBoardSize(20)}
        >
          20
        </button>
      </div>
      <div className="controlInnerContainer">
        <span>Sudėtingumas:</span>
        <button
          className={gameLevel === 1 ? "active" : "inactive"}
          onClick={() => setGameLevel(1)}
        >
          1
        </button>
        <button
          className={gameLevel === 2 ? "active" : "inactive"}
          onClick={() => setGameLevel(2)}
        >
          2
        </button>
        <button
          className={gameLevel === 3 ? "active" : "inactive"}
          onClick={() => setGameLevel(3)}
        >
          3
        </button>
        <button
          className={gameLevel === 4 ? "active" : "inactive"}
          onClick={() => setGameLevel(4)}
        >
          4
        </button>
      </div>
    </div>
  );
}
