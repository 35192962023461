import React from "react";
import "./gameWon.scss";
export default function GameWon({ setGameWon }) {
  return (
    <div className="gameOver">
      Laimejai!!!
      <button className="newGame" onClick={() => setGameWon(false)}>
        Kartoti
      </button>
    </div>
  );
}
