import React from "react";
import "./gameOver.scss";
export default function GameOver({ setGameover }) {
  return (
    <div className="gameOver">
      Pralaimejai
      <button className="newGame" onClick={() => setGameover(false)}>
        Kartoti
      </button>
    </div>
  );
}
