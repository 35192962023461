import React, { useState, useEffect } from "react";
import GameOver from "../gameOver/GameOver";
import GameWon from "../gameWon/GameWon";
import Controls from "../controls/Controls";
import "./gameBoard.scss";

export default function GameBoard() {
  const [board, setBoard] = useState();
  const [gameOver, setGameover] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [gameLevel, setGameLevel] = useState(1);
  const [boardSize, setBoardSize] = useState(5);

  function createGameBoard(size) {
    const boardConstruct = [];
    for (let i = 0; i < size; i++) {
      const subBoard = [];
      for (let j = 0; j < size; j++) {
        subBoard.push({
          x: i,
          y: j,
          bomb:
            Math.floor(Math.random() * (9 - gameLevel)) === 0 ? true : false,
          content: "",
          revealed: false,
          flagged: false,
        });
      }
      boardConstruct.push(subBoard);
    }
    const finishedBoard = setValues(boardConstruct);
    return finishedBoard;
  }

  function setValues(subArr) {
    const arr = [...subArr];

    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr[i].length; j++) {
        if (arr[i][j].bomb === false) {
          let bombCount = 0;

          if (i - 1 >= 0 && j - 1 >= 0 && arr[i - 1][j - 1].bomb === true) {
            bombCount++;
          }
          if (i - 1 >= 0 && arr[i - 1][j].bomb === true) {
            bombCount++;
          }
          if (i - 1 >= 0 && j + 1 < arr.length && arr[i - 1][j + 1].bomb) {
            bombCount++;
          }
          if (j - 1 >= 0 && arr[i][j - 1].bomb === true) {
            bombCount++;
          }
          if (j + 1 < arr.length && arr[i][j + 1].bomb === true) {
            bombCount++;
          }
          if (
            i + 1 < arr.length &&
            j - 1 >= 0 &&
            arr[i + 1][j - 1].bomb === true
          ) {
            bombCount++;
          }
          if (i + 1 < arr.length && arr[i + 1][j].bomb === true) {
            bombCount++;
          }
          if (
            i + 1 < arr.length &&
            j + 1 < arr.length &&
            arr[i + 1][j + 1].bomb === true
          ) {
            bombCount++;
          }
          arr[i][j].content = bombCount > 0 ? bombCount : "";
        }
      }
    }
    return arr;
  }

  function handleClick(event) {
    if (
      event.target.className === "square" ||
      event.target.className === "hiddenSquare"
    ) {
      const [xLocation, yLocation] = [
        event.target.dataset.xloc,
        event.target.dataset.yloc,
      ];
      if (board[xLocation][yLocation].revealed === false) {
        const subArr = [...board];
        subArr[xLocation][yLocation].revealed = true;
        setBoard(subArr);
        if (subArr[xLocation][yLocation].bomb === true) {
          setGameover(true);
        }
      }
    }
  }
  function handleRightClick(event) {
    event.preventDefault();
    if (
      event.target.className === "hiddenSquare" ||
      event.target.className === "flaggedSquare"
    ) {
      const [xLocation, yLocation] = [
        event.target.dataset.xloc,
        event.target.dataset.yloc,
      ];
      const newArr = [...board];
      if (newArr[xLocation][yLocation].revealed === false) {
        newArr[xLocation][yLocation].flagged === false
          ? (newArr[xLocation][yLocation].flagged = true)
          : (newArr[xLocation][yLocation].flagged = false);
        setBoard(newArr);
      }
    }
  }
  useEffect(() => {
    setBoard(createGameBoard(boardSize));
  }, []);

  useEffect(() => {
    if (gameOver === false && gameWon === false) {
      setBoard(createGameBoard(boardSize));
    }
  }, [gameOver, gameWon, gameLevel, boardSize]);

  useEffect(() => {
    if (board !== undefined && board.length > 0) {
      revealClearFields();
    }
  }, [board]);

  function revealClearFields() {
    const newArr = [...board];
    let changes = 0;
    for (let i = 0; i < newArr.length; i++) {
      for (let j = 0; j < newArr.length; j++) {
        if (
          newArr[i][j].revealed === true &&
          newArr[i][j].content === "" &&
          newArr[i][j].bomb === false
        ) {
          if (j - 1 >= 0) {
            if (newArr[i][j - 1].revealed === false) {
              newArr[i][j - 1].revealed = true;
              changes++;
            }
          }
          if (j - 1 >= 0 && i - 1 >= 0) {
            if (newArr[i - 1][j - 1].revealed === false) {
              newArr[i - 1][j - 1].revealed = true;
              changes++;
            }
          }
          if (i - 1 >= 0) {
            if (newArr[i - 1][j].revealed === false) {
              newArr[i - 1][j].revealed = true;
              changes++;
            }
          }
          if (i - 1 >= 0 && j + 1 < newArr.length) {
            if (newArr[i - 1][j + 1].revealed === false) {
              newArr[i - 1][j + 1].revealed = true;
              changes++;
            }
          }
          if (j + 1 < newArr.length) {
            if (newArr[i][j + 1].revealed === false) {
              newArr[i][j + 1].revealed = true;
              changes++;
            }
          }
          if (i + 1 < newArr.length && j + 1 < newArr.length) {
            if (newArr[i + 1][j + 1].revealed === false) {
              newArr[i + 1][j + 1].revealed = true;
              changes++;
            }
          }
          if (i + 1 < newArr.length) {
            if (newArr[i + 1][j].revealed === false) {
              newArr[i + 1][j].revealed = true;
              changes++;
            }
          }
          if (i + 1 < newArr.length && j - 1 >= 0) {
            if (newArr[i + 1][j - 1].revealed === false) {
              newArr[i + 1][j - 1].revealed = true;
              changes++;
            }
          }
        }
      }
    }
    if (changes != 0) {
      setBoard(newArr);
    }
    checkWin();
  }

  function checkWin() {
    const newArr = [...board];
    let fieldsLeft = 0;
    for (let i = 0; i < newArr.length; i++) {
      for (let j = 0; j < newArr.length; j++) {
        if (newArr[i][j].revealed === false && newArr[i][j].bomb === false) {
          fieldsLeft++;
        }
      }
    }
    if (fieldsLeft === 0) {
      setGameWon(true);
    }
  }

  if (gameOver === true) {
    if (board && board.length > 0) {
      return (
        <>
          <Controls
            setGameLevel={setGameLevel}
            setBoardSize={setBoardSize}
            gameLevel={gameLevel}
            boardSize={boardSize}
          />
          <div className="gameBoard">
            {board.map((line) => {
              return (
                <div className="gameBoardLine" key={Math.random() * 9999999}>
                  {line.map((square) => {
                    return (
                      <div
                        data-xloc={square.x}
                        data-yloc={square.y}
                        data-flagged={square.flagged}
                        className={
                          square.revealed
                            ? "square"
                            : square.flagged
                            ? "flaggedSquare"
                            : "hiddenSquare"
                        }
                        onClick={handleClick}
                        onContextMenu={handleRightClick}
                        key={Math.random() * 9999999}
                      >
                        {!square.revealed ? (
                          ""
                        ) : square.bomb ? (
                          <img src="./mine.png" />
                        ) : (
                          square.content
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <GameOver setGameover={setGameover} />
        </>
      );
    }
  } else if (gameWon === true) {
    if (board && board.length > 0) {
      return (
        <>
          <Controls
            setGameLevel={setGameLevel}
            setBoardSize={setBoardSize}
            gameLevel={gameLevel}
            boardSize={boardSize}
          />
          <div className="gameBoard">
            {board.map((line) => {
              return (
                <div className="gameBoardLine" key={Math.random() * 9999999}>
                  {line.map((square) => {
                    return (
                      <div
                        data-xloc={square.x}
                        data-yloc={square.y}
                        data-flagged={square.flagged}
                        className={
                          square.revealed
                            ? "square"
                            : square.flagged
                            ? "flaggedSquare"
                            : "hiddenSquare"
                        }
                        onClick={handleClick}
                        onContextMenu={handleRightClick}
                        key={Math.random() * 9999999}
                      >
                        {!square.revealed ? (
                          ""
                        ) : square.bomb ? (
                          <img src="./mine.png" />
                        ) : (
                          square.content
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <GameWon setGameWon={setGameWon} />
        </>
      );
    }
  } else {
    if (board && board.length > 0) {
      return (
        <>
          <Controls
            setGameLevel={setGameLevel}
            setBoardSize={setBoardSize}
            gameLevel={gameLevel}
            boardSize={boardSize}
          />
          <div className="gameBoard">
            {board.map((line) => {
              return (
                <div className="gameBoardLine" key={Math.random() * 9999999}>
                  {line.map((square) => {
                    return (
                      <div
                        data-xloc={square.x}
                        data-yloc={square.y}
                        data-flagged={square.flagged}
                        className={
                          square.revealed
                            ? "square"
                            : square.flagged
                            ? "flaggedSquare"
                            : "hiddenSquare"
                        }
                        onClick={handleClick}
                        onContextMenu={handleRightClick}
                        key={Math.random() * 9999999}
                      >
                        {!square.revealed ? (
                          ""
                        ) : square.bomb ? (
                          <img src="./mine.png" />
                        ) : (
                          square.content
                        )}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      );
    } else {
      return <div className="loadingWindow">LOADING...</div>;
    }
  }
}
